import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './style/documents.css'

export default function OneDoc() {
    const navigate = useNavigate()
    const {pathname} = useLocation()
    const id = pathname.slice(5);
    const doc = localStorage.getItem('doc')
    const docArr = JSON.parse(doc)
    const docOne = docArr.filter((e)=> e.id === id)
    console.log(docOne);

  return (
    <>
    <div className='header'>
        <h1 className='header-title'>{docOne[0]?.name}</h1>
    </div>
    <div id="docs-container">
        <div className='doc-pdf mx-3'>
            <img src={`../icons/docs/template1.jpg`} alt='!#' style={{width:'100%'}}/>
        </div>
        <div className='doc-pdf mx-3'>
            <img src={`../icons/docs/template2.jpg`} alt='!#' style={{width:'100%'}}/>
        </div>
        <div className='doc-pdf mx-3'>
            <img src={`../icons/docs/template3.jpg`} alt='!#' style={{width:'100%'}}/>
        </div>
    </div>
    <div className='fixed-bottom' style={{display:'flex' , width:'inherit' , justifyContent:'center' }}>
    <button className='doc-btn'
        onClick={() => navigate('/doc')}
        style={{
            backgroundColor: '#388DA8',
            color: '#fff',
            cursor:  'pointer'
        }}
        >
        Вернуться
    </button>
    </div>
    </>

  )
}
