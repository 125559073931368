import React, { useEffect, useState } from 'react'
import './style/documents.css'
import CheckBox from '../Components/CheckBox';
import { Button, Modal } from 'react-bootstrap';
import MyModal from '../Components/MyModal';
import { useNavigate } from 'react-router-dom';

export default function DocumentsPage() {
    const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate()
    const [isDisabled, setIsDisabled] = useState(true);
    const test = [
        {id: '1' , name: 'Договор на предоставление услуг интернет-связи №151', date: 'Сегодня, 15:44', pdf: 'Иванов Иван Иванович'},
        {id: '2' , name: 'Договор на подключение к высокоскоростному интернету', date: 'Вчера, 22:11', pdf: 'Петров Петр Петрович'},
        {id: '3' , name: 'Договор на оказание услуг интернет-телевидения', date: '27.08.2024', pdf: 'Сидоров Сидор Сидорович'},
    ]
    const handleCheckboxChange = () => {
        setIsChecked(prevState => !prevState);
        setIsDisabled(!isDisabled);
      };
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(()=> {
        localStorage.setItem('doc' , JSON.stringify(test))
    },[test])
    const test1 = localStorage.getItem('doc')
  return (
    <div id='doc-page'>
        <div className='fixed-top'>
            <div className='header'>
                <h1 className='header-title'>Иванов<br/> Иван Иванович</h1>
                <img src='./icons/gos.png' className='logo-gos'/>       
            </div>
            <div className='doc-block'>
                <div className='doc-subtitle-block'>
                    <p className='doc-subtitle'>Ожидают вашей подписи</p>
                    <p className='doc-subtitle'>3</p>
                </div>
                <div className='all-doc'>
                    {test.map((e)=> {
                        return (
                            <div key={e.id} onClick={()=> navigate(`/doc/${e.id}`)}>
                            <div className='doc-item'>
                                <div>
                                    <div className='doc-name'>{e.name}</div>
                                    <div className='doc-date'>{e.date}</div>
                                </div>
                                <div style={{width:'24px' , height:'24px'}}>
                                    <img src='./icons/Arrow.svg'  style={{width:'20px' , height:'20px'}}/>
                                </div>
                            </div>
                                <hr style={{margin:'0px'}}/>
                            </div>
                        )
                    })}
                </div>
                <div className='doc-check'>
                    <CheckBox 
                        label="Я проверил подписываемые документы и подтверждаю ознакомление"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                    />
                </div>
                
                <div className='fixed-bottom' style={{display:'flex' , width:'inherit' , justifyContent:'center' }}>
                    <button className='doc-btn'
                        onClick={() => setIsModalOpen(true)}
                        style={{
                            backgroundColor: isDisabled ? 'rgb(56 141 168 / 28%)' : '#388DA8',
                            color: isDisabled ? '#666' : '#fff',
                            cursor: isDisabled ? 'not-allowed' : 'pointer'
                        }}
                        >
                        Продолжить
                    </button>
                    <MyModal
                        show={isModalOpen}
                        onHide={() => setIsModalOpen(false)}
                    />

    
                </div>
            </div>
        </div>
    </div>
  )
}
