import React, { useEffect, useState } from 'react'
import './style/greeting.css'
import CheckBox from '../Components/CheckBox';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import { ServerApi } from '../api/ServerApi';

export default function GreetingsPage() {
    const serverApi = new ServerApi()
    const navigate = useNavigate()
    const [isChecked, setIsChecked] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [cookies, setCookie] = useCookies(['operator_id', 'user_id', 'esia_url', 'esia_token', 'link_token']);
    const operator_id = searchParams.get("c")
    const user_id = searchParams.get("u")
  
    const handleCheckboxChange = () => {
      setIsChecked(prevState => !prevState);
      setIsDisabled(!isDisabled);
    };
    const handleButtonClick = () => {
        if (!isDisabled) {
            navigate('/view')
        }
      };

      useEffect(() => {
        if(!operator_id && !cookies.esia_url) {
            serverApi.getUrl().then((response) => {
                console.log(response)
                let expires = new Date();
                expires.setTime(expires.getTime() + 86400000);
                setCookie('esia_url', response.url, {path: 'https://app.idgos.ru', expires: expires})
            })
        }
        
    }, [])
    useEffect(()=>{
        if(operator_id && user_id) {
            localStorage.setItem('operator_id', operator_id);
            localStorage.setItem('user_id', user_id)
            //!Esia Url
            serverApi.getOperatorInfo(operator_id).then((response) => {
                if(response.status == 200) {
                    localStorage.setItem('operator_name',response.data.name)
                }
            })
            let token_exp = new Date();
            token_exp.setTime(token_exp.getTime() + 86400000);
            const link_token = base64_encode(JSON.stringify({operator_id: operator_id, user_id: user_id, token_exp:token_exp}));
            setCookie('link_token', link_token, {path: 'https://app.idgos.ru', expires: token_exp})
        }
    },[searchParams])
  return (
    <div id='greeting'>
        <div id='greeting-block' className='mx-2'>
            <div className='greeting-img-block'>
                <img src='../icons/logo-idgos.png' alt='!#' className='logo-idgos'/>
            </div>
            <div className='greeting-text-block'>
                <h1 className='greeting-title'>Добро пожаловать на IdGos</h1>
                <p className='greeting-subtitle'>Сервис для подписания документов через ГосУслуги</p>
            </div>
            {/* <div className='greeting-input-block'>
                <input 
                    className='greeting-input'
                    type="checkbox" 
                    checked={isChecked} 
                    onChange={handleCheckboxChange}
                />
                <span className='greeting-input-text'>Я согласен с политикой конфиденциальности</span>
            </div> */}
            <CheckBox 
                label="Я согласен с политикой конфиденциальности"
                checked={isChecked}
                onChange={handleCheckboxChange}
            />
            
            <div className='greeting-btn-block'>
                <button 
                    onClick={handleButtonClick} 
                    disabled={isDisabled}
                    style={{
                    backgroundColor: isDisabled ? 'rgb(56 141 168 / 28%)' : '#388DA8',
                    color: isDisabled ? '#666' : '#fff',
                    border: 'none',
                    borderRadius: '12px',
                    padding:'14px 32px 14px 32px',
                    cursor: isDisabled ? 'not-allowed' : 'pointer'
                    }}
                >
                    Продолжить
                </button>
            </div>
            
        </div>
    </div>
  )
}
