import sendRequest from "./sendRequest";
import axios from "axios";

class ServerApi {
    constructor() {
        this.esiaUrl = 'https://esia.idgos.ru/esia/index.php?redirectUrl=';
        this.serverUrl = 'https://esia.idgos.ru/server/router.php';
        this.token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OCwibG9naW4iOiJtYWthcmtpbiIsImlhdCI6MTcxNTg0MjU1OSwiZXhwIjoxNzE3MDUyMTU5fQ.1gteigYgoJs--14cAj272SDhVsDXRcv0EK1RLGJ_J6Q'
    }

    async getUrl() {
        return await sendRequest(`${this.esiaUrl}https://app.idgos.ru`, '')
    }

    async checkCode(user_id, code) {
        const body = JSON.stringify({
            action: 'checkCode',
            data: {
                user_id: user_id,
                code: code
            }
        })
        return await axios.post('https://esia.idgos.ru/server/router.php', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }
    async getOperatorInfo(operator_id) {
        const body = JSON.stringify({
            action: 'getOperatorInfo',
            data: {
                operator_id: operator_id
            }
        })
        return await axios.post('https://esia.idgos.ru/server/router.php', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    async getUserToken(user_id, operator_id) {
        const body = JSON.stringify({
            action: 'getUserToken',
            data: {
                user_id: user_id,
                operator_id: operator_id
            }
        })
        return await axios.post('https://esia.idgos.ru/server/router.php', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    async setUserToken(user_id, operator_id, token, time_exp) {
        const body = JSON.stringify({
            action: 'setUserToken',
            data: {
                user_id: user_id,
                operator_id: operator_id,
                token: token,
                time_exp: time_exp
            }
        })
        return await axios.post('https://esia.idgos.ru/server/router.php', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    async getOperatorConfig(app_key, login) {
        const body = JSON.stringify({
            action: 'getOperatorConfig',
            data: {
                app_key: app_key,
                login: login
            }
        })
        return await axios.post('https://esia.idgos.ru/server/router.php', body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    async getCustomerId(url, key, login) {
        return axios.get(
            `${url}?key=${key}&cat=customer&action=get_abon_id&data_typer=login&data_value=${login}`
        ).then((response) => {
            if(response.status == 200) {
                return response.data
            }
            return {error: response.data.error}
        }).catch((error) => {
            return {error: error.response.data.error}
        })
    }

    async getCustomerData(url, key, customer_id) {
        return axios.get(
            `${url}?key=${key}&cat=customer&action=get_data&customer_id=${customer_id}`
        ).then((response) => {
            if(response.status == 200) {
                return response.data
            }
            return {error: response.data.error}
        }).catch((error) => {
            return {error: error.response.data.error}
        })
    }

    async getDocumentsForSign(body) {
        return axios.post(this.serverUrl, body, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
        .then((response) => {
            if(response.status == 200) {
                return response.data
            }
            return {error: response.data.error}
        })
        .catch((error) => {
            return {error: error.response.data.error}
        })
    }

    // async signFile(body) {
    //     return await axios.post('https://esia.idgos.ru/server/router.php', JSON.stringify(body), {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${this.token}`
    //         }
    //     })
    // }
    async createPreview(body) {
        return await axios.post('https://esia.idgos.ru/dogovorTest/createPreview.php', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async sendPostRequest(body) {
        return await axios.post('https://esia.idgos.ru/server/router.php', JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }

    async getDocumentsForSign2(body) {
        return await axios.post(`https://esia.idgos.ru/server/router.php`, JSON.stringify(body), {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.token}`
            }
        })
    }
    
    

}

export {ServerApi}