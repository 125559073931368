import React from 'react'
import './style/documents.css'

export default function SignedDocPage() {
    const test = [
        {id: '1' , name: 'Договор на предоставление услуг интернет-связи №151', date: 'Сегодня, 15:44', pdf: 'Иванов Иван Иванович'},
        {id: '2' , name: 'Договор на подключение к высокоскоростному интернету', date: 'Вчера, 22:11', pdf: 'Петров Петр Петрович'},
        {id: '3' , name: 'Договор на оказание услуг интернет-телевидения', date: '27.08.2024', pdf: 'Сидоров Сидор Сидорович'},
    ]
    const handleCall = () => {
        window.location.href = 'tel:+74999990130';
    };
  return (
<div id='doc-page'>
        <div className='fixed-top'>
            <div className='header'>
                <h1 className='header-title'>Иванов<br/> Иван Иванович</h1>
                <img src='./icons/gos.png' className='logo-gos'/>       
            </div>
            <div className='doc-block'>
                <div className='doc-subtitle-block'>
                    <p className='doc-subtitle'>Подписанные Документы</p>
                    {/* <p className='doc-subtitle'>3</p> */}
                </div>
                <div className='all-doc' style={{marginBottom:'20px'}}>
                    {test?.map((e)=> {
                        return (
                            <div key={e.id}>
                            <div className='signed-item mx-2'>
                                <div>
                                    <div className='signed-name'>{e.name + '.pdf'}</div>
                                    <div className='signed-date'>Скачать документ</div>
                                </div>
                                <div style={{width:'32px' , height:'32px'}}>
                                    <img src='./icons/load.svg'  style={{width:'24px' , height:'24px', marginLeft:'10px'}}/>
                                </div>
                            </div>
                            </div>
                        )
                    })}
                </div>
                <div>
                    <a href='https://memchik.ru//images/memes/58aafaa3b1c7e36ea210f0d9.jpg' target='_blank'>
                        <div className='signed-item1 mx-2'>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <img src='./icons/Ruble.svg'  style={{width:'20px' , height:'20px', marginRight:'10px'}}/>
                                <div className='signed-name'>Совершить оплату</div>
                            </div>
                            <div style={{width:'24px' , height:'24px'}}>
                                <img src='./icons/Arrow.svg'  style={{width:'20px' , height:'20px', marginLeft:'10px'}}/>
                            </div>
                        </div>
                    </a>
                    <a href='https://intronex.ru/' target='_blank'>
                        <div className='signed-item1 mx-2'>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <img src='./icons/Global.svg'  style={{width:'20px' , height:'20px', marginRight:'10px'}}/>
                                <div className='signed-name'>На сайт оператора</div>
                            </div>
                            <div style={{width:'24px' , height:'24px'}}>
                                <img src='./icons/Arrow.svg'  style={{width:'20px' , height:'20px', marginLeft:'10px'}}/>
                            </div>
                        </div>
                    </a>
                    <div className='signed-item1 mx-2' onClick={handleCall}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <img src='./icons/Call.svg'  style={{width:'20px' , height:'20px', marginRight:'10px'}}/>
                            <div className='signed-name'>Позвонить оператору</div>
                        </div>
                        <div style={{width:'24px' , height:'24px'}}>
                            <img src='./icons/Arrow.svg'  style={{width:'20px' , height:'20px', marginLeft:'10px'}}/>
                        </div>
                    </div>
                    <div>
                        <div className='mx-3 my-4' style={{display:'flex' , flexDirection:'column' , justifyContent:'center'}}>
                            <p className='doc-subtitle'>Отправить договор на E-mail</p>
                            <input className='input-mail'
                                type="email"
                                placeholder="Введите ваш email"
                                required
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent:'center'}}>
                            <button className='doc-btn'
                                style={{
                                    backgroundColor:'#388DA8',
                                    color: '#fff',
                                    cursor: 'pointer',
                                }}
                                >
                                Продолжить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    )
}
