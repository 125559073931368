import React from 'react'
import './style/view.css'
import { useNavigate } from 'react-router-dom';

export default function ViewPage() {
  const navigate = useNavigate()
  const operator_name = localStorage.getItem('operator_name')
  return (
    <div id='view-page'>
        <div id='view-block'>
          <div className='view-img-block'>
            <img src='../icons/logo-idgos.png' alt='!#' className='logo-idgos'/>
          </div>
          <div className='view-text-block'>
            <p className='view-subtitle'>
              Уважаемый Абонент, Вы находитесь в Сервисе для заключения договора на Услуги связи с Компанией {operator_name}
            </p>
            <h1 className='view-title p-4'>Подписание договора на услуги будет в три этапа на раз два три{')'}</h1>
          </div>
          <div className='view-step-block'>
            <div className='step1'>
              <h1 className='view-title'>1</h1>
              <p className='view-subtitle1'>Авторизация (Госуслуги)</p>
            </div>
            <div className='step2'>
              <h1 className='view-title'>2</h1>
              <p className='view-subtitle1'>Ознакомление с договором</p>
            </div>
            <div className='step3'>
              <h1 className='view-title'>3</h1>
              <p className='view-subtitle1'>Подписание через СМС</p>
            </div>
          </div>
          <div className='view-text-block'>
            <p className='view-subtitle mx-2'>
            Нажимая продолжить Вы даёте согласие на обработку Персональных данных {operator_name} а также его ком мерческому представителю ООО «Синтегра» 
            </p>
          </div>
          <div className='fixed-bottom' style={{display:'flex' , width:'inherit' , justifyContent:'center' }}>
                <button className='view-btn' onClick={()=> navigate('/doc')}>
                    Продолжить
                </button>
            </div>
        </div>
    </div>
  )
}
